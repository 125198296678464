<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}

.mx-input {
  height: 3em !important;
}

body {
  background: red !important;
}

.mx-datepicker-range > input {
  height: 3em !important;
}

#header {
  position: relative !important;
}

.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 17px;
  font-weight: 500px;
  color: #000;
}

.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

.page-not-available {
  background-color: #4b92fc;
  height: 100vh;
  width: 100%;
  color: #fff;
  font-size: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-not-available > p {
  text-align: center;
}
.page-not-available > p:nth-child(1) {
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.page-not-available > p:nth-child(2) {
  color: rgb(34, 59, 102);
}
</style>

<template>
  <div >
    <header id="header">
      <a href="#hero-section">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
      </a>
      <nav>
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/">
            <img src="../../assets/img/arrow.png" style="width: 45px" />
          </router-link>
        </ul>
      </nav>
    </header>

    <div class="d-flex align-items-center container__login">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      >
      </loading>
      <CContainer fluid>
        <CRow class="justify-content-center">
          <CCol md="6">
            <CCard class="mx-md-4 mb-0">
              <CCardBody class="p-4">
                <CForm >
                  <h1 class="title__login" style="color: black !important">
                    Incidencia Cobro Doble
                  </h1>

                  <hr style="border-style: solid; border-width: 1px" />

                  <!-- //TODO: Tipo documento Y N° Documento -->
                  <CRow>
                    <CCol class="mb-sm-3" md="6" sm="12">
                      <label class="label__input">Tipo documento</label>
                      <p
                        v-if="isTipoDocumento"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo tipo de documento está vacio
                      </p>
                      <select
                        v-model="tipoDocumento"
                        class="form-control"
                        style="color: black !important"
                        @change="changeTipoDocumento($event)"
                      >
                        <option value="">--Seleccionar--</option>
                        <option value="DNI">DNI</option>
                        <option value="RUC">RUC</option>
                        <option value="Carnet de extranjeria">
                          Carnet de extranjeria
                        </option>
                        <option value="Pasaporte">Pasaporte</option>
                        <option value="CDI">CDI</option>
                      </select>
                    </CCol>
                    <!--//TODO: Numero documento-->
                    <CCol md="6" sm="12">
                      <label class="label__input">N° Documento </label>
                      <p
                        v-if="isNumeroDocumento"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*) El campo Numero documento esta vacio
                      </p>
                      <CInput
                        @keypress="validateNumber($event)"
                        @keyup="validateLength($event)"
                        v-model="numeroDocumento"
                      ></CInput>
                    </CCol>
                  </CRow>
                  <!--//TODO: Suscripcion Contratada y numero telefonico-->
                  <CRow>
                    <CCol md="6" sm="12">
                      <label class="label__input">Suscripción contratada</label>
                      <p
                        v-if="isSuscripcion"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo suscripción está vacio
                      </p>
                      <select
                        v-model="suscripcion"
                        class="form-control mb-3"
                        style="color: black !important"
                      >
                        <option value="">--Seleccionar--</option>
                        <option value="El Comercio Impreso">
                          El Comercio Impreso
                        </option>
                        <option value="El Comercio Impreso + Digital">
                          El Comercio Impreso + Digital
                        </option>
                        <option value="Gestión Impreso">Gestión Impreso</option>
                        <option value="Gestión Impreso + Digital">
                          Gestión Impreso + Digital
                        </option>
                      </select>
                    </CCol>
                     <CCol md="6" sm="12">
                       <label class="label__input">Número telefónico</label>
                         <p v-if="isNumeroTelefonico" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo número telefónico está vacio</p>
                        <CInput
                           v-model="numeroTelefonico"
                           @keypress="validateNumber($event)"
                           maxlength="9"
                           style="color:black !important;"
                             >
                        </CInput>
                    </CCol>
                  </CRow>
                  <!-- //TODO: Fecha de pago y Monto Pagado -->
                  <CRow>
                    <CCol md="6" sm="12">
                      <!--//TODO: Fecha de Pago -->
                      <label class="label__input">Fecha de pago </label>
                      <p
                        v-if="isFechaPago"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)Debe Seleccionar Una Fecha
                      </p>
                      <CInput v-model="fechaPago" type="date"> </CInput>
                    </CCol>
                    <CCol md="6" sm="12">
                      <!--//TODO: Monto Pagago-->
                      <label class="label__input">Monto pagado </label>
                      <p
                        v-if="isMontoPagado"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)Falta Monto Pagado
                      </p>
                      <CInput
                        @keypress="validateNumberAndPoint($event)"
                        v-model="montoPagado"
                        placeholder="50.90"
                      >
                      </CInput>
                    </CCol>
                  </CRow>
                  <!--//TODO: numero Tarjeta-->
                  <CRow>
                    <CCol sm="6" md="6">
                      <label class="label__input">Número de Tarjeta</label>
                      <p
                        v-if="isNumeroTarjetaInicial"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*) El campo esta vacio
                      </p>
                      <CInput
                      
                        v-model="numeroTarjetaInicial"
                        placeholder="Primeros 6 digitos de tu tarjeta"
                        @keypress="validateNumber($event)"
                        maxlength="6"
                      >
                      </CInput>
                    </CCol>
                     <CCol sm="6" md="6">
                      <p
                        v-if="isNumeroTarjetaFinal"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*) El campo esta vacio
                      </p>
                      <CInput
                        style="margin-top: 32px; !important"
                        v-model="numeroTarjetaFinal"
                        placeholder="ultimos 4 digitos de tu tarjeta"
                        @keypress="validateNumber($event)"
                        maxlength="4"
                      >
                      </CInput>
                    </CCol>
                  </CRow>
                  <!--//TODO: Constancia de Cobro-->
                  <CRow>
                     <CCol sm="6" md="12" class="mb-2">
                     <label class="label__input" for="archivo">Constancia de cobro</label>
                     <input  @change="asignar" name="archivo" type="file" class=" form-control-file" >
                      <p
                        v-if="isConstanciaCobro"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*) El Campo Esta Vacio
                      </p>
                     </CCol>
                  </CRow>
                  <!-- //TODO: correo -->
                  <CRow>
                    <CCol>
                      <label class="label__input">Correo </label>
                      <p
                        v-if="isCorreo"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo correo está vacio
                      </p>
                      <CInput
                        placeholder="ejemplo@gmail.com..."
                        type="email"
                        v-model="correo"
                        style="color: black !important"
                      >
                      </CInput>
                    </CCol>
                  </CRow>
                  <!-- //TODO: Enviar -->
                  <button
                    type="button"
                    class="btn-comercio btn__ingresar"
                    @click="registrarFreshdesk()"
                  >
                    Enviar
                  </button>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
 
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Table from "../base/Table.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ProgressBars from '../base/ProgressBars.vue';


export default {
  name: "PageIncidenciaCobroDoble",
  data() {
    return {
      /* FIXME: valores nesesarios */
      documento: null,
      isLoading: false,
      fullPage: true,

      isTipoDocumento: false,
      isNumeroDocumento: false,
      isSuscripcion: false,
      isNumeroTelefonico:false,
      isFechaPago: false,
      isMontoPagado: false,
      isNumeroTarjetaInicial: false,
      isNumeroTarjetaFinal: false,
      isConstanciaCobro: false,
      isCorreo: false,

      tipoDocumento: "",
      numeroDocumento: "",
      suscripcion: "",
      numeroTelefonico:"",
      fechaPago: "",
      montoPagado: "",
      numeroTarjetaInicial: "",
      numeroTarjetaFinal: "",
      constanciaCobro: "",
      correo: "",
      file:"",


      
    nombreArchivo:"",
    tipoArchivo:"",

     

      flag: false,
    };
  },

  components: {
    Loading,
    Table,
    DatePicker,
    ProgressBars,
  },
  methods: {
    /* TODO: Codificando el archivo en Base64*/
    async asignar(e){
        this.file = e.target.files[0];
     },
    /* TODO: Validaciones */
    /* TODO:validar tipo documento */
    changeTipoDocumento(event) {
      let vm = this;
      let id = event.target.value;
      vm.tipoDocumento = id;
      vm.numeroDocumento = "";
    },
    /* TODO:validar numero */
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    /* TODO:validar tamaño del numero */
    validateLength(event) {
      let vm = this;
      let val = event.target.value;
      let len = 20;
      if (vm.tipoDocumento == "DNI") len = 8;
      else if (vm.tipoDocumento == "RUC") len = 11;
      if (val.length > len) {
        vm.numeroDocumento = event.target.value.substring(0, len);
      }
    },
    validateNumberAndPoint(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9.]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    validateNumberAndLetter(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9a-zA-z]$");
      if (!myRex.test(val)) event.preventDefault();
    },
  async  registrarFreshdesk() {

      let vm = this;

      let cloudinary_url = "https://api.cloudinary.com/v1_1/dambjf1k2/image/upload"
      let cloudinary_upload_preset ="wzmmgmlb"

 
    
      vm.isTipoDocumento = false;
      vm.isNumeroDocumento = false;
      vm.isSuscripcion = false;
      vm.isNumeroTelefonico = false;
      vm.isFechaPago = false;
      vm.isMontoPagado = false;
      vm.isNumeroTarjetaInicial= false,
      vm.isNumeroTarjetaFinal = false,
      vm.isCorreo = false;

      if (vm.tipoDocumento == "") {
        vm.isTipoDocumento = true;
      }

      if (vm.numeroDocumento == "") {
        vm.isNumeroDocumento = true;
      }

      if (vm.suscripcion == "") {
        vm.isSuscripcion = true;
      }
      if(vm.numeroTelefonico == ""){
        vm.isNumeroTelefonico = true;
      }
      if (vm.fechaPago == "") {
        vm.isFechaPago = true;
      }

      if (vm.montoPagado == "") {
        vm.isMontoPagado = true;
      }

      if (vm.numeroTarjetaInicial == "") {
        vm.isNumeroTarjetaInicial = true;
      }
      if (vm.numeroTarjetaFinal == "") {
        vm.isNumeroTarjetaFinal = true;
      }
      if (vm.correo == "") {
        vm.isCorreo = true;
      }

      if (
        vm.isTipoDocumento == false &&
        vm.isNumeroDocumento == false &&
        vm.isSuscripcion == false &&
        vm.isNumeroTelefonico == false &&
        vm.isFechaPago == false &&
        vm.isMontoPagado == false &&
        vm.isNumeroTarjetaInicial == false &&
        vm.isNumeroTarjetaFinal == false &&
        vm.isConstanciaCobro == false &&
        vm.isCorreo == false
      ) {
        if (
          vm.numeroTelefonico.length != 7 &&
          vm.numeroTelefonico.length != 9
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "El campo número telefónico debe contener 7 u 9 números.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else if (
          vm.numeroTelefonico.length == 9 &&
          vm.numeroTelefonico.charAt(0) != "9"
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "El campo número telefónico debe comenzar con el dígito 9.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else if (
          !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            vm.correo
          )
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "La dirección de correo es incorrecta.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else {

          const formData = new FormData();
          formData.append('file',this.file)
          formData.append('upload_preset',cloudinary_upload_preset)

        const res = await axios.post(cloudinary_url,formData,{headers:{'Content-Type':'multipart/form-data'}});
      
        vm.constanciaCobro = res.data.secure_url;

          let data = {

            tipoDocumento: vm.tipoDocumento,
            numeroDocumento: vm.numeroDocumento,
            suscripcion: vm.suscripcion,
            numeroTelefonico:vm.numeroTelefonico,
            fechaPago: vm.fechaPago,
            montoPagado: vm.montoPagado,
            numeroTarjetaInicial: vm.numeroTarjetaInicial,
            numeroTarjetaFinal: vm.numeroTarjetaFinal,
            constanciaCobro: vm.constanciaCobro,
            correo: vm.correo,
            tipo: 4,
          };

          //console.log("Data =>", data);

         await axios
            .post(`${process.env.VUE_APP_ROOT_API}/incidencias`, data)
            .then(function (res) {
              vm.isLoading = false;
                  if (res.data.data.code == 1) {
                      vm.$fire({
                              title: "Constancia de reclamo enviada",
                              html:`${res.data.data.message} <br/><br/> Gracias por escribirnos.`,
                              type: "success",
                              timer: 4000,
                                }).then((r) => {});

                              vm.tipoDocumento = "";
                              vm.numeroDocumento= "";
                              vm.suscripcion= "";
                              vm.numeroTelefonico="";               
                              vm.fechaPago= "";
                              vm.montoPagado= "";
                              vm.numeroTarjetaInicial= "";
                              vm.numeroTarjetaFinal= "";
                              vm.constanciaCobro= "";
                              vm.fileName="";
                              vm.mimeType="";
                              vm.correo= "";
              } else {
                    vm.$fire({
                            title: "Seguridad",
                            html: res.data.data.message,
                            type: "info",
                            timer: 40000,
                          }).then((r) => { console.log("dismin", r);
                           });
              }
 
            })
            .catch((error) => {
                          vm.isLoading = false;
                          vm.$fire({
                          title: "Error",
                          text: "Ocurrio un error en el servidor",
                          type: "error",
                          timer: 6000,
                          }).then((r) => {});
                    });
        }


      }
    },

    redirectLanding() {
      let vm = this;
      vm.$router.push("/");
    }
    
  },

  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();
  
    const date = new Date();
    const hour = date.getHours();

    if ((hour >= 0 && hour < 7) || hour >= 19) {
      vm.flag = true;
    }
    
  },
};
</script>