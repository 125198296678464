<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}
</style>
<template>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100vh;
      background-color: rgb(80, 115, 149);
    "
  >
    <div>
      <div
        v-if="code == '1'"
        style="width: 37em; height: 10vh; display: flex; align-items: center"
      >
        <div>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Check_green_icon.svg/1200px-Check_green_icon.svg.png"
            alt=""
            width="50"
          />
        </div>
        <div style="margin-left: 10px">
          <span style="color: #fff; font-weight: bold; font-size: 1.8em"
            >Su cuenta se ha validado correctamente.</span
          >
        </div>
      </div>

      <div
        v-if="code == '0'"
        style="width: 37em; height: 10vh; display: flex; align-items: center"
      >
        <div>
          <img
            src="https://c0.klipartz.com/pngpicture/68/949/sticker-png-check-mark-x-mark-computer-icons-cross-heart-angle-desktop-wallpaper-sign-code-check-mark.png "
            alt=""
            width="50"
          />
        </div>
        <div style="margin-left: 10px">
          <span style="color: #fff; font-weight: bold; font-size: 1.8em"
            >Su cuento no se pudo activar, vuelva a registrarse por favor.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Login",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      code: "-1",
    };
  },
  components: {
    Loading,
  },
  methods: {
    activateCuenta() {
      let vm = this;
      vm.isLoading = true;
      let data = {
        token: this.$route.query.token,
      };
      axios
        .post(`${process.env.VUE_APP_ROOT_API}/activar-cuenta`, data)
        .then(function (res) {
          vm.isLoading = false;
          vm.code = res.data.data.code;
        })
        .catch((error) => {
          vm.isLoading = false;
        });
    },
    redirectLanding() {
      let vm = this;
      vm.$router.push("/");
    },
  },
  mounted() {
    let vm = this;
    vm.activateCuenta();
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();
  },
};
</script>
