<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}

.mx-input {
  height: 3em !important;
}

body {
  background: red !important;
}

.mx-datepicker-range > input {
  height: 3em !important;
}

#header {
  position: relative !important;
}

.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 17px;
  font-weight: 500;
  color: #000;
}

.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

.page-not-available {
  background-color: #4b92fc;
  height: 100vh;
  width: 100%;
  color: #fff;
  font-size: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-not-available > p {
  text-align: center;
}
.page-not-available > p:nth-child(1) {
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.page-not-available > p:nth-child(2) {
  color: rgb(34, 59, 102);
}
</style>

<template>
  <div>
    <header id="header">
      <a href="#hero-section">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
      </a>
      <nav>
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/">
            <img src="../../assets/img/arrow.png" style="width: 45px" />
          </router-link>
        </ul>
      </nav>
    </header>
    <div class="d-flex align-items-center container__login">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      >
      </loading>
      <CContainer fluid>
        <CRow class="justify-content-center">
          <CCol md="6">
            <CCard class="mx-md-4 mb-0">
              <CCardBody class="p-4">
                <CForm>
                  <h1 class="title__login" style="color: black !important">
                    Alicia Ventas
                  </h1>

                  <hr style="border-style: solid; border-width: 1px" />

                  <!-- Tipo de Documento -->
                  <div class="form-group">
                    <label class="label__input">Tipo de documento</label>
                    <p
                      v-if="isTipoDocumento"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo tipo de documento está vacio
                    </p>
                    <select
                      v-model="tipoDocumento"
                      class="form-control"
                      style="color: black !important"
                      @change="changeTipoDocumento($event)"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="DNI">DNI</option>
                      <option value="RUC">RUC</option>
                      <option value="Carnet de extranjeria">
                        Carné de extranjería
                      </option>
                      <option value="Pasaporte">Pasaporte</option>
                      <option value="CDI">CDI</option>
                    </select>
                  </div>
                  <!-- Numero de Documento-->
                  <div class="form-group">
                    <label class="label__input">Número de documento</label>
                    <p
                      v-if="isNumeroDocumento"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo número de documento está vacio
                    </p>
                    <CInput
                      v-model="numeroDocumento"
                      @keypress="validateNumber($event)"
                      @keyup="validateLength($event)"
                      style="color: black !important"
                    >
                    </CInput>
                  </div>
                  <!-- Nombres completos -->
                  <div class="form-group">
                    <label class="label__input">Nombres completos</label>
                    <p
                      v-if="isNombresCompletos"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo nombres completos está vacio
                    </p>
                    <CInput
                      v-model="nombresCompletos"
                      @keypress="validateText($event)"
                      style="color: black !important"
                    >
                    </CInput>
                  </div>
                  <!-- Telefono -->
                  <div class="form-group">
                    <label class="label__input"># de contacto</label>
                    <p
                      v-if="isNumeroTelefonico"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo número telefónico está vacio
                    </p>
                    <CInput
                      v-model="numeroTelefonico"
                      @keypress="validateNumber($event)"
                      maxlength="9"
                      style="color: black !important"
                    >
                    </CInput>
                  </div>
                  <!-- Correo -->
                  <div class="form-group">
                    <label class="label__input">Email</label>
                    <p
                      v-if="isCorreo"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo correo está vacio
                    </p>
                    <CInput v-model="correo" style="color: black !important">
                    </CInput>
                  </div>
                  <!-- Observacion -->
                  <div class="form-group">
                    <label class="label__input">Consulta</label>
                    <p
                      v-if="isObservacion"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo observacion está vacio
                    </p>
                    <CTextarea
                      v-model="observacion"
                      style="color: black !important"
                    ></CTextarea>
                  </div>


                  <!-- Plan -->
                  <div class="form-group">
                    <label class="label__input">Plan</label>
                    <p
                      v-if="isPlan"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo tipo de consulta está vacio
                    </p>
                    <select
                      v-model="plan"
                      class="form-control"
                      style="color: black !important"
                    >
                      <!-- <option value="">--Seleccionar--</option> -->
                      <option value="Digital">
                        Digital
                      </option>
                      <!-- <option value="Digital + Impreso">Digital + Impreso</option>
                      <option value="PerúQuiosco">Perú Quiosco</option> -->
                    </select>
                  </div>

                  <!-- Producto -->
                  <div class="form-group">
                    <label v-if="plan" class="label__input">Diario</label>
                    <p
                      v-if="isProducto"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo tipo de consulta está vacio
                    </p>
                    <select
                      v-if="plan == 'Digital' || plan == 'Digital + Impreso'"
                      v-model="producto"
                      class="form-control"
                      style="color: black !important"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="Comercio">El Comercio</option>
                      <option value="Gestion">Gestión</option>
                    </select>
                    <select
                      v-if="plan == 'PerúQuiosco'"
                      v-model="producto"
                      class="form-control"
                      style="color: black !important"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="Diarios">Diarios</option>
                      <option value="Todo incluido">Todo incluido</option>
                    </select>
                  </div>


                  <!-- Periodo -->
                  <div class="form-group">
                    <label v-if="producto" class="label__input">Periodo</label>
                    <p
                      v-if="isPeriodo"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo tipo de consulta está vacio
                    </p>

                    <!-- opcion 1  -->

                    <select
                      v-if="producto == 'Comercio' && plan == 'Digital'"
                      v-model="periodo"
                      class="form-control"
                      style="color: black !important"
                    >
                      <!-- Opcion 1 -->
                      <option value="">--Seleccionar--</option>
                      <option value="Mensual a S/4 el 1er mes, luego pagarás S/20 al mes">
                        Mensual a S/4 el 1er mes, luego pagarás S/20 al mes
                      </option>
                      <option value="Anual a S/10 el mes por 12 meses. Pago único de S/120">
                        Anual a S/10 el mes por 12 meses. Pago único de S/120
                      </option>
                    </select>

                    <!-- opcion 2 -->
                    <select
                      v-if="producto == 'Gestion' && plan == 'Digital'"
                      v-model="periodo"
                      class="form-control"
                      style="color: black !important"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="Mensual a S/19 el 1er mes, luego pagarás S/39 al mes">
                        Mensual a S/19 el 1er mes, luego pagarás S/39 al mes
                      </option>
                      <option value="Anual a S/15 el mes por 12 meses. Pago único de S/180">
                        Anual a S/15 el mes por 12 meses. Pago único de S/180
                      </option>
                    </select>

                    <!-- opcion 3 -->
                    <select
                      v-if="producto == 'Comercio' && plan == 'Digital + Impreso'"
                      v-model="periodo"
                      class="form-control"
                      style="color: black !important"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="Consulta de PQ">Consulta de PQ</option>
                      <option value="Reparto durante viernes, sábados y domingos. Paga el 1er mes S/35, luego S/50 al mes">
                        Reparto durante viernes, sábados y domingos. Paga el 1er mes S/35, luego S/50 al mes
                      </option>
                      <option value="Reparto los 7 días de la semana. Paga S/75 al mes">
                        Reparto los 7 días de la semana. Paga S/75 al mes
                      </option>
                    </select>

                    <!-- opcion 4-->
                    <select
                      v-if="producto == 'Gestion' && plan == 'Digital + Impreso'"
                      v-model="periodo"
                      class="form-control"
                      style="color: black !important"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="Reparto de lunes a viernes. Paga S/49 el 1er mes, luego S/69 al mes">
                        Reparto de lunes a viernes. Paga S/49 el 1er mes, luego S/69 al mes
                      </option>
                    </select>

                    <!-- opcion 5 -->
                    <select
                      v-if="plan == 'PerúQuiosco' && producto == 'Diarios'"
                      v-model="periodo"
                      class="form-control"
                      style="color: black !important"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="Mensual a S/68 el mes">
                        Mensual a S/68 el mes
                      </option>
                      <option value="Anual a S/42 el mes, durante 12 meses. Único pago de S/504">
                        Anual a S/42 el mes, durante 12 meses. Único pago de S/504
                      </option>
                    </select>

                    <!-- opcion 6 -->
                    <select
                      v-if="plan == 'PerúQuiosco' && producto == 'Todo incluido'"
                      v-model="periodo"
                      class="form-control"
                      style="color: black !important"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="Mensual a S/86 al mes">
                        Mensual a S/86 al mes
                      </option>
                      <option value="Anual a S/52 al mes, durante 12 meses. Único pago de S/624">
                        Anual a S/52 al mes, durante 12 meses. Único pago de S/624
                      </option>
                    </select>

                  </div>

                  <button
                    type="button"
                    class="btn-comercio btn__ingresar"
                    @click="registrarFreshdesk()"
                  >
                    Enviar
                  </button>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Table from "../base/Table.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "PageFueraDeHorarioAliciaVentas",
  data() {
    return {
      isLoading: false,
      fullPage: true,

      isTipoDocumento: false,
      isNumeroDocumento: false,
      isNombresCompletos: false,
      isNumeroTelefonico: false,
      isCorreo: false,
      isObservacion: false,
      isPlan: false,
      isProducto: false,
      isPeriodo: false,

      tipoDocumento: "",
      numeroDocumento: "",
      nombresCompletos: "",
      numeroTelefonico: "",
      correo: "",
      observacion: "",
      plan: "Digital", // cuando se habiliten los tres planes, se debe cambiar a ""
      producto: "",
      periodo: "",
      
      flag: false,
    };
  },
  components: {
    Loading,
    Table,
    DatePicker,
  },
  methods: {
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    validateNumberAndLetter(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9a-zA-z]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    validateLength(event) {
      let vm = this;
      let val = event.target.value;
      let len = 20;
      if (vm.tipoDocumento == "DNI") len = 8;
      else if (vm.tipoDocumento == "RUC") len = 11;
      if (val.length > len) {
        vm.numeroDocumento = event.target.value.substring(0, len);
      }
    },
    changeTipoDocumento(event) {
      let vm = this;
      let id = event.target.value;
      vm.tipoDocumento = id;
      vm.numeroDocumento = "";
    },

    registrarFreshdesk() {
      let vm = this;

      vm.isTipoDocumento = false;
      vm.isNumeroDocumento = false;
      vm.isNombresCompletos = false;
      vm.isNumeroTelefonico = false;
      vm.isCorreo = false;
      vm.isObservacion = false;
      vm.isPlan = false;
      vm.isProducto = false;
      vm.isPeriodo = false;

      if (vm.tipoDocumento == "") {
        vm.isTipoDocumento = true;
      }
      if (vm.numeroDocumento == "") {
        vm.isNumeroDocumento = true;
      }
      if (vm.nombresCompletos == "") {
        vm.isNombresCompletos = true;
      }
      if (vm.numeroTelefonico == "") {
        vm.isNumeroTelefonico = true;
      }
      if (vm.correo == "") {
        vm.isCorreo = true;
      }
      if (vm.observacion == "") {
        vm.isObservacion = true;
      }
      if (vm.plan == "") {
        vm.isPlan = true;
      }
      if (vm.producto == "") {
        vm.isProducto = true;
      }
      if (vm.periodo == "") {
        vm.isPeriodo = true;
      }
      if (
        vm.isTipoDocumento == false &&
        vm.isNumeroDocumento == false &&
        vm.isNombresCompletos == false &&
        vm.isNumeroTelefonico == false &&
        vm.isCorreo == false &&
        vm.isObservacion == false &&
        vm.isPlan == false &&
        vm.isProducto == false &&
        vm.isPeriodo == false
      ) {
        if (
          vm.numeroTelefonico.length != 7 &&
          vm.numeroTelefonico.length != 9
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "El campo número telefónico debe contener 7 u 9 números.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else if (
          vm.numeroTelefonico.length == 9 &&
          vm.numeroTelefonico.charAt(0) != "9"
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "El campo número telefónico debe comenzar con el dígito 9.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else if (
          !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            vm.correo
          )
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "La dirección de correo es incorrecta.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else {
          let data = {
            tipoDocumento: vm.tipoDocumento,
            numeroDocumento: vm.numeroDocumento,
            nombresCompletos: vm.nombresCompletos,
            numeroTelefonico: vm.numeroTelefonico,
            correo: vm.correo,
            observacion: vm.observacion,
            plan: vm.plan,
            producto: vm.producto,
            periodo: vm.periodo,
            tipo : 20
          };
          console.log("Data =>", data);
          axios
            .post(`${process.env.VUE_APP_ROOT_API}/alicia-ventas`, data)
            .then(function (res) {
              vm.isLoading = false;
              if (res.data.data.code == 1) {
                vm.$fire({
                  title: "Felicitaciones",
                  text: res.data.data.message,
                  type: "success",
                  timer: 4000,
                }).then((r) => {});

                vm.tipoDocumento="";
                vm.numeroDocumento="";
                vm.nombresCompletos="";
                vm.numeroTelefonico="";
                vm.correo="";
                vm.observacion="";
                vm.plan="";
                vm.producto="";
                vm.periodo="";

              } else {
                vm.$fire({
                  title: "Seguridad",
                  html: res.data.data.message,
                  type: "info",
                  timer: 10000,
                }).then((r) => {
                  console.log("dismin", r);
                });
              }
            })
            .catch((error) => {
              vm.isLoading = false;
              vm.$fire({
                title: "Error",
                text: "Ocurrio un error en el servidor",
                type: "error",
                timer: 4000,
              }).then((r) => {});
            });


        }
      }
    },
    redirectLanding() {
      let vm = this;
      vm.$router.push("/");
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();

    const date = new Date();
    const hour = date.getHours();

    if ((hour >= 0 && hour < 7) || hour >= 19) {
      vm.flag = true;
    }
  },
};
</script>