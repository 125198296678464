<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}

.mx-input {
  height: 3em !important;
}

body {
  background: red !important;
}

.mx-datepicker-range > input {
  height: 3em !important;
}

#header {
  position: relative !important;
}

.btn-search-ubicacion {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-search-ubicacion:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.btn__ingresar__ubicacion {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 40px;
  font-weight: 500 !important;
  font-size: 16px !important;
  letter-spacing: 1px;
}

.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 17px;
  font-weight: 500px;
  color: #000;
}

.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

.page-not-available {
  background-color: #4b92fc;
  height: 100vh;
  width: 100%;
  color: #fff;
  font-size: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-not-available > p {
  text-align: center;
}
.page-not-available > p:nth-child(1) {
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.page-not-available > p:nth-child(2) {
  color: rgb(34, 59, 102);
}
</style>

<template>
  <div>
    <header id="header">
      <a href="#hero-section">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
      </a>
      <nav>
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/">
            <img src="../../assets/img/arrow.png" style="width: 45px" />
          </router-link>
        </ul>
      </nav>
    </header>

    <div class="d-flex align-items-center container__login">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      >
      </loading>
      <CContainer fluid>
        <CRow class="justify-content-center">
          <CCol md="6">
            <CCard class="mx-md-4 mb-0">
              <CCardBody class="p-4">
                <CForm>
                  <h1 class="title__login" style="color: black !important">
                    Servicio Técnico BPI
                  </h1>

                  <hr style="border-style: solid; border-width: 1px" />
                  <!-- //TODO: Fecha de pago y Monto Pagado -->
                  <CRow>
                    <CCol md="8" sm="12">
                      <label class="label__input">N° Documento </label>
                      <p
                        v-if="isNumeroDocumento"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*) El campo Numero documento esta vacio
                      </p>
                      <CInput
                        @keypress="validateNumber($event)"
                        @keyup="validateLength($event)"
                        v-model="documentNumber"
                      ></CInput>
                    </CCol>
                    <CCol md="4" sm="12">
                      <label class="label__input"> .</label>
                      <button
                        type="button"
                        class="btn-search-ubicacion btn__ingresar__ubicacion"
                        @click="verificar($event)"
                      >
                        Verificar
                      </button>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6" sm="12">
                      <!--//TODO: Fecha de Pago -->
                      <label class="label__input">Nombre Completo</label>
                      <p
                        v-if="isNombre"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo correo es incorrecto
                      </p>
                      <CInput
                        disabled
                        v-model="nombre"
                        id="inputNombre"
                        style="color: black !important"
                      >
                      </CInput>
                    </CCol>
                    <CCol md="6" sm="12">
                      <!--//TODO: Fecha de Pago -->
                      <label class="label__input">Correo</label>
                      <p
                        v-if="isCorreo"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo correo es incorrecto
                      </p>
                      <CInput
                        disabled
                        v-model="correo"
                        id="inputCorreo"
                        style="color: black !important"
                      >
                      </CInput>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="12" sm="12">
                      <label class="label__input">Tipo de Solicitud</label>
                      <p
                        v-if="isTipoSolicitud"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo tipo de solicitud está vacio
                      </p>
                      <select
                        disabled
                        v-model="tipoSolicitud"
                        class="form-control"
                        name=""
                        id="inputTipoSolicitud"
                        @change="changeCategory($event)"
                      >
                        <option
                          v-for="item in arrCategory"
                          :value="item.id"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                      <br />
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol md="12" sm="12">
                      <label class="label__input">Detalle de solicitud</label>
                      <select
                        disabled
                        v-model="detalleSolicitud"
                        class="form-control"
                        name=""
                        id="inputDetalleSolicitud"
                      >
                        <option
                          v-for="item in arrSubCategory"
                          :value="item.id"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                      <br />
                    </CCol>
                  </CRow>
                  <!--//TODO: numero Tarjeta-->
                  <CRow>
                    <CCol sm="12" md="12">
                      <!--//TODO: Monto Pagago-->
                      <label class="label__input"
                        >Descripción de Solicitud</label
                      >
                      <p
                        v-if="isDescripcion"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo descripción está vacio
                      </p>
                      <CTextarea
                      disabled
                        v-model="descripcion"
                        id="inputDescripcion"
                        style="color: black !important"
                        rows="7"
                      ></CTextarea>
                    </CCol>
                  </CRow>
                  <!--
                  <CRow>
                    <CCol sm="12" md="12" class="mb-2">
                      <label class="label__input" for="archivo"
                        >Imagen de solicitud</label>
                      <input
                        @change="updateFile"
                        name="archivo"
                        type="file"
                        class="form-control-file"
                      >
                      <p
                        v-if="isIncidencia"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*) El Campo Esta Vacio
                      </p>
                    </CCol>
                  </CRow>
                  -->
                  <!--//TODO: Constancia de Cobro-->
                  <!-- //TODO: correo -->
                  <!-- //TODO: Enviar -->
                  <button
                    type="button"
                    class="btn-comercio btn__ingresar"
                    @click="registerServicioTecnico()"
                  >
                    Enviar
                  </button>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Table from "../base/Table.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ProgressBars from "../base/ProgressBars.vue";

export default {
  name: "PageIncidenciaCobroDoble",
  data() {
    return {
      /* FIXME: valores nesesarios */
      isLoading: false,
      fullPage: true,

      isIncidencia: false,
      isNombre: false,
      isCorreo: false,
      isTipoSolicitud: false,
      isDescripcion: false,
      isDetalleSolicitud: false,
      isDocumentNumber: false,

      documentNumber: "",
      categoryId: "",
      nombre: "",
      correo: "",
      tipoSolicitud: "",
      detalleSolicitud: "",
      descripcion: "",
      arrCategory: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "Requerimiento" },
        { id: "2", name: "Incidencia" },
        { id: "3", name: "Interrupción del servicio" },
        { id: "4", name: "Dashboard" },
      ],
      arrSubCategory: [{ id: "", name: "--Seleccionar--" }],
      flag: false,
    };
  },

  components: {
    Loading,
    Table,
    DatePicker,
    ProgressBars,
  },
  methods: {
    /*
    async updateFile(e) {
      let vm = this;
      const file = e.target.files[0];
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        vm.fileBase64 = fileReader.result;
        console.log(vm.fileBase64);
      };
    },
    */
    /* TODO:validar tamaño del numero */
    async verificar() {
      let vm = this;
      if (vm.documentNumber == "") {
        vm.isDocumentNumber = true;
      } else {
        let data = {
          document_number: vm.documentNumber,
        };

        axios
          .get(`${process.env.VUE_APP_ROOT_API}/support/validate-user`, {
            params: data,
          })
          .then(function (res) {

            vm.isLoading = false;
            if (res.data.data.code == 1) {
              vm.$fire({
                title: "¡Listo!",
                text: "Sus datos fueron verificados con éxito",
                type: "success",
                timer: 4000,
              }).then((r) => {

                vm.nombre = res.data.data.data.firstname;
                vm.correo = res.data.data.data.email;

                document.getElementById("inputNombre").disabled = true;
                document.getElementById("inputCorreo").disabled = true;
                document.getElementById("inputTipoSolicitud").disabled = false;
                document.getElementById(
                  "inputDetalleSolicitud"
                ).disabled = false;
                document.getElementById("inputDescripcion").disabled = false;
              });
            } else {
              vm.$fire({
                title: "¡Lo sentimos!",
                text: "Número de documento no identificado",
                type: "error",
                timer: 10000,
              }).then((r) => {
                console.log("dismin", r);
                document.getElementById("inputNombre").disabled = true;
                document.getElementById("inputCorreo").disabled = true;
                document.getElementById("inputTipoSolicitud").disabled = true;
                document.getElementById(
                  "inputDetalleSolicitud"
                ).disabled = true;
                document.getElementById("inputDescripcion").disabled = true;
              });
            }
          })
          .catch((error) => console.log(error));
      }
    },
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    /* TODO:validar tamaño del numero */
    validateLength(event) {
      let vm = this;
      let val = event.target.value;
      let len = 20;

      if (val.length > len) {
        vm.documentNumber = event.target.value.substring(0, len);
      }
    },
    changeCategory(event) {
      let vm = this;
      let id = event.target.value;
      vm.categoryId = id;
      vm.isLoading = true;

      if (id == "") {
        vm.arrSubCategory = [{ id: "", name: "--Seleccionar--" }];
      } else if (id == "1") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "Incluir nuevas Keyword", name: "Incluir nuevas Keyword" },
          {
            id: "Actualización de promociones",
            name: "Actualización de promociones",
          },
          {
            id: "Actualización de textos o imágenes",
            name: "Actualización de textos o imágenes",
          },
          {
            id: "Cambio de flujos conversacionales",
            name: "Cambio de flujos conversacionales",
          },
          {
            id: "Cambios en la derivaciones C2C/CHATTERS",
            name: "Cambios en la derivaciones C2C/CHATTERS",
          },
          { id: "Otros", name: "Otros" },
        ];
      } else if (id == "2") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          {
            id: "Incidencia en Adquirir Suscripción",
            name: "Incidencia en Adquirir Suscripción",
          },
          {
            id: "Incidencia en Información de suscripción",
            name: "Incidencia en Información de suscripción",
          },
          {
            id: "Incidencia en Club El Comercio",
            name: "Incidencia en Club El Comercio",
          },
          {
            id: "Incidencia en PerúQuiosco",
            name: "Incidencia en PerúQuiosco",
          },
          {
            id: "Incidencia en Cambios y solicitudes",
            name: "Incidencia en Cambios y solicitudes",
          },
          {
            id: "Incidencia en Cancelar Suscripción",
            name: "Incidencia en Cancelar Suscripción",
          },
          { id: "Incidencia en Formulario", name: "Incidencia en Formulario" },
          { id: "Incidencia en Dashboard", name: "Incidencia en Dashboard" },
          {
            id: "Incidencia en Circulación",
            name: "Incidencia en Circulación",
          },
          { id: "Otros", name: "Otros" },
        ];
      } else if (id == "3") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          {
            id: "Caída total del servicio en sus 3 canales",
            name: "Caída total del servicio en sus 3 canales",
          },
        ];
      } else if (id == "4") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "Desactualizado", name: "Desactualizado" },
        ];
      }

      vm.isLoading = false;
    },
    registerServicioTecnico() {
      let vm = this;

      vm.isCorreo = false;
      vm.isTipoSolicitud = false;
      vm.isDescripcion = false;

      if (vm.nombre == "") {
        vm.isNombre = true;
      }

      if (vm.correo == "") {
        vm.isCorreo = true;
      }

      if (
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          vm.correo
        )
      ) {
        vm.isCorreo = false;
      } else {
        vm.isCorreo = true;
      }

      if (vm.tipoSolicitud == "") {
        vm.isTipoSolicitud = true;
      }

      if (vm.descripcion == "") {
        vm.isDescripcion = true;
      }

      if (
        vm.isCorreo == false &&
        vm.isTipoSolicitud == false &&
        vm.isDescripcion == false
      ) {
        let correo = vm.correo;
        let nombre = vm.nombre;
        console.log(correo);

        //incidenciaFile: vm.fileBase64,
        let data = {
          email: correo,
          name: nombre,
          request_category: vm.tipoSolicitud,
          request_subcategory: vm.detalleSolicitud,
          description: vm.descripcion,
        };

        //console.log("Data =>", data);

        axios
          .post(`${process.env.VUE_APP_ROOT_API}/support/register-ticket`, data)
          .then(function (res) {
            vm.isLoading = false;
            if (res.data.data.code == 1) {
              vm.$fire({
                title: "Solicitud recibida",
                html: `${res.data.data.message} <br/><br/> Gracias por escribirnos.`,
                type: "success",
                timer: 6000,
              }).then((r) => {});

              vm.correo = "";
              vm.tipoSolicitud = "";
              vm.descripcion = "";
            } else {
              vm.$fire({
                title: "Seguridad",
                html: res.data.data.message,
                type: "info",
                timer: 6000,
              }).then((r) => {
                console.log("dismin", r);
              });
            }
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "Ocurrio un error en el servidor" + error.message,
              type: "error",
              timer: 6000,
            }).then((r) => {
              console.log(r);
            });
          });
      }
    },

    redirectLanding() {
      let vm = this;
      vm.$router.push("/");
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();
  },
};
</script>