<style lang="scss" scoped>

  .tolttip-message{
    display:none !important;
  }

  .mx-input{
    height: 3em !important;
    }


  .mx-datepicker-range > input {
      height: 3em !important;
  }

  #header{
    position: relative!important;
  }

  .btn-comercio {
    text-decoration: none;
    padding: 7.5px 10px;
    font-weight: 600;
    font-size: 1em;
    color: #ffffff;
    background-color: rgb(0, 38, 230);
    border-radius: 6px;
    width: 100%;
    border: 2px solid #0016b0;
  }
  .btn-comercio:hover {
    background-color: rgb(64, 204, 255);
    border: 2px solid rgb(64, 204, 255);
  }

  .title__login {
    color: #21458d;
    font-size: 30px;
    font-weight: 600;
  }

  .btn__ingresar {
    background: #4b92fc !important;
    border-color: #4b92fc !important;
    height: 45px;
    font-weight: 500 !important;
    font-size: 18px !important;
    letter-spacing: 1px;
  }

  .container__login {
    margin-top: 2%;
    // height: 85vh;
  }

  .p__input {
    margin-bottom: 0px;
    position: relative;
    top: -10px;
    font-size: 13px;
    color: #4d4d4d;
  }

.page-not-available{
  background-color: #4b92fc;
  height:100vh;
  width: 100%;
  color:#fff;
  font-size:2em;
  display:flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

  .page-not-available > p{
    text-align:center;
  }
    .page-not-available > p:nth-child(1){
      font-size: 2.5em;
      font-weight:bold;
      text-shadow: 4px 4px 5px rgba(0,0,0,0.2);
    }

    .page-not-available > p:nth-child(2){
      color:rgb(34, 59, 102);
    }

</style>

<template>
  <div >
    <header id="header">
      <a href="#hero-section">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
      </a>
      <nav>
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/">
            <img src="../../assets/img/arrow.png" style="width: 45px" />
          </router-link>
        </ul>
      </nav>
    </header>


    <div class="d-flex align-items-center container__login">
      <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :is-full-page="fullPage">
      </loading>
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-md-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h1 class="title__login" style="color:black !important;">Problema de reparto</h1>

                <hr style="border-style: solid;border-width: 1px;">

                <b-alert variant="success" show style="color:black !important;">
                  Lamento lo ocurrido. 
                  Recuerda que todo reclamo debe ser presentado dentro de los primeros 07 días desde que se presentó el inconveniente. 
                  Se está reportando el incidente al área correspondiente y en un plazo no mayor a 72 horas resolveré tu consulta al  mail que registraste cuando te suscribiste.
                  <br>¡Espero haberte ayudado! 😀
                </b-alert>

                <div class="form-group">
                  <label class="label__input">Tipo de incidencia de reparto</label>
                  <p v-if="isTipoIncidencia" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo tipo de incidencia de reparto está vacio</p>
                  <select
                      v-model="tipoIncidencia"
                      class="form-control"
                      style="color:black !important;"
                      @change="changeTipoIncidenciaReparto($event)"
                  >
                      <option value="">--Seleccionar--</option>
                      <option value="No me llego el diario">No me llegó el diario</option>
                      <option value="Edición incompleta/maltratada">Edición incompleta/maltratada</option>
                      <option value="Horario de entrega">Horario de entrega</option>
                      <option value="Forma de entrega">Forma de entrega</option>
                  </select>
                </div>

                <div id="c-observacion-incompleta-maltratada" v-show="isCobservacionIncompletaMaltratada">
                  <label class="label__input">Detalla la incidencia edición incompleta/maltratada</label>
                  <CInput
                  v-model="observacionIncompletaMaltratada"
                  style="color:black !important;"
                  placeholder="Ejm: Las hojas estan incompletas"
                  >
                  </CInput>
                </div>

                <div class="form-group">
                  <label class="label__input">Tipo de documento</label>
                  <p v-if="isTipoDocumento" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo tipo de documento está vacio</p>
                  <select
                      v-model="tipoDocumento"
                      class="form-control"
                      style="color:black !important;"
                      @change="changeTipoDocumento($event)"
                  >
                      <option value="">--Seleccionar--</option>
                      <option value="DNI">DNI</option>
                      <option value="RUC">RUC</option>
                      <option value="Carnet de extranjeria">Carné de extranjería</option>
                      <option value="Pasaporte">Pasaporte</option>
                      <option value="CDI">CDI</option>
                  </select>
                </div>

                <label class="label__input">Número de documento</label>
                <p v-if="isNumeroDocumento" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo número de documento está vacio</p>
                <CInput
                v-model="numeroDocumento"
                @keypress="validateNumber($event)"
                @keyup="validateLength($event)"
                style="color:black !important;"
                >
                </CInput>

                <div class="form-group">
                  <label class="label__input">Suscripción contratada</label>
                  <p v-if="isSuscripcion" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo suscripción está vacio</p>
                  <select
                      v-model="suscripcion"
                      class="form-control"
                      style="color:black !important;"
                  >
                      <option value="">--Seleccionar--</option>
                      <option value="El Comercio Impreso">El Comercio Impreso</option>
                      <option value="El Comercio Impreso + Digital">El Comercio Impreso + Digital</option>
                      <option value="Gestión Impreso">Gestión Impreso</option>
                      <option value="Gestión Impreso + Digital">Gestión Impreso + Digital</option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="label__input">Tipo de calle</label>
                  <p v-if="isTipoLugar" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo tipo de calle está vacio</p>
                  <select
                      v-model="tipoLugar"
                      class="form-control"
                      style="color:black !important;"
                  >
                      <option value="">--Seleccionar--</option>
                      <option value="Pasaje">Pasaje</option>
                      <option value="Jirón">Jirón</option>
                      <option value="Calle">Calle</option>
                      <option value="Avenida">Avenida</option>
                  </select>
                </div>

                <label class="label__input">Nombre de la calle</label>
                <p v-if="isLugar" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo calle está vacio</p>
                <CInput
                v-model="lugar"
                >
                </CInput>

                <div class="form-group">
                  <label class="label__input">Número exterior</label>
                  <p v-if="isTipoUbicacion" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo número exterior está vacio</p>
                  <select
                      v-model="tipoUbicacion"
                      class="form-control"
                      style="color:black !important;"
                  >
                      <option value="">--Seleccionar--</option>
                      <option value="Número Exterior">Número exterior</option>
                      <option value="Manzana-Lote">Manzana - lote</option>
                      <option value="Kilometro">Kilómetro</option>
                  </select>
                </div>
               
                <label class="label__input">Número exterior</label>
                  <p v-if="isUbicacion" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo número exterior está vacio</p>
                  <CInput
                  v-model="ubicacion"
                  style="color:black !important;"
                  >
                  </CInput>

                <div class="form-group">
                  <label class="label__input">Interior o departamento</label>
                  <p v-if="isTipoEspacio" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo interior o departamento está vacio</p>
                  <select
                      v-model="tipoEspacio"
                      class="form-control"
                      style="color:black !important;"
                  >
                      <option value="">--Seleccionar--</option>
                      <option value="Interior">Interior</option>
                      <option value="Departamento">Departamento</option>
                  </select>
                </div>

                <label class="label__input">Interior o departamento</label>
                <p v-if="isEspacio" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo interior o departamento está vacio</p>
                <CInput
                v-model="espacio"
                style="color:black !important;"
                >
                </CInput>

                <label class="label__input">Urbanización</label>
                <p v-if="isUrbanizacion" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo urbanización está vacio</p>
                <CInput
                v-model="urbanizacion"
                style="color:black !important;"
                >
                </CInput>

                <div class="form-group">
                  <label class="label__input">Ciudad</label>
                  <p v-if="isCiudad" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo ciudad está vacio</p>
                  <select
                      v-model="ciudad"
                      class="form-control"
                      @change="changeCiudad($event)"
                      style="color:black !important;"
                  >
                      <option value="">--Seleccionar--</option>
                      <option value="Lima">Lima</option>
                      <option value="Trujillo">Trujillo</option>
                      <option value="Chiclayo">Chiclayo</option>
                      <option value="Arequipa">Arequipa</option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="label__input">Distrito</label>
                  <p v-if="isDistrito" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo distrito está vacio</p>
                  <select
                      v-model="distrito"
                      class="form-control"
                      style="color:black !important;"
                  >
                  <option value="">--Seleccionar--</option>
                  <option
                    v-for="item in distritos"
                    :value="item.nombre"
                    :key="item.id"
                  >
                    {{ item.nombre }}
                  </option>
                  </select>
                </div>

                <label class="label__input">Referencia</label>
                <p v-if="isReferencia" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo referencia está vacio</p>
                <CInput
                v-model="referencia"
                style="color:black !important;"
                placeholder="Avenida, intersección, jirón, calle"
                >
                </CInput>


                <div class="form-group">
                  <label class="label__input">Forma de Entrega</label>
                  <p v-if="isFormaEntrega" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo forma de entrega está vacio</p>
                  <select
                      v-model="formaEntrega"
                      class="form-control"
                      style="color:black !important;"
                  >
                      <option value="">--Seleccionar--</option>
                      <option value="Debajo de la puerta principal">Debajo de puerta principal</option>
                      <option value="Debajo de puerta cochera">Debajo de puerta cochera</option>
                      <option value="Buzón">Buzón</option>
                      <option value="Recepción a vigilante">Recepción a vigilante</option>
                      <option value="Puerta cochera">Puerta cochera</option>
                      <option value="Por la reja">Por la reja</option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="label__input">Dias de incidencia</label>
                  <p v-if="isDiasIncidencia" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo dias de incidencia está vacio</p>
                  <date-picker type="date" v-model="diasIncidencia" range format="DD-MM-YYYY" style="width:100% !important;"></date-picker>
                </div>

                <label class="label__input">Observación</label>
                <p v-if="isObservacion" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo observacion está vacio</p>
                <CTextarea                
                  v-model="observacion"
                  style="color:black !important;"></CTextarea>

                <label class="label__input">Correo</label>
                <p v-if="isCorreo" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo correo está vacio</p>
                <CInput
                v-model="correo"
                style="color:black !important;"
                >
                </CInput>
                <button type="button" class="btn-comercio btn__ingresar" @click="registrarFreshdesk()">Enviar</button>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>

  </div>

</template>

<script>

import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Table from '../base/Table.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';


export default {
  name: 'PageIncidenciaReparto',
  data(){
    return{
        isLoading: false,
        fullPage: true,
        isTipoIncidencia:false,
        tipoIncidencia:"",
        isCobservacionIncompletaMaltratada:false,
        observacionIncompletaMaltratada:"",
        isTipoDocumento:false,
        tipoDocumento:"",
        isNumeroDocumento:false,
        numeroDocumento:"",
        isSuscripcion:false,
        suscripcion:"",
        isTipoLugar:false,
        tipoLugar:"",
        isLugar:false,
        lugar:"",
        isTipoUbicacion:false,
        tipoUbicacion:"",
        isUbicacion:false,
        ubicacion:"",
        isTipoEspacio:false,
        tipoEspacio:"",
        isEspacio:false,
        espacio:"",
        isUrbanizacion:false,
        urbanizacion:"",
        isCiudad:false,
        ciudad:"",
        isDistrito:false,
        distrito:"",
        isReferencia:false,
        referencia:"",
        isFormaEntrega:false,
        formaEntrega:"",
        distritos:[],
        isDiasIncidencia:false,
        diasIncidencia:"",
        isCorreo:false,
        correo:"",
        isObservacion:false,
        observacion:"",
        flag:false,
    }
  },
  components:{
    Loading,
    Table,
    DatePicker
  },
  methods:{
    changeTipoIncidenciaReparto(event){
      let vm = this;
      let val = event.target.value;
      if(val == "Edición incompleta/maltratada"){
        vm.isCobservacionIncompletaMaltratada = true;
        vm.observacionIncompletaMaltratada = "";
      }else{
        vm.isCobservacionIncompletaMaltratada = false;
      }

    },
    changeTipoDocumento(event){
      let vm = this;
      let id = event.target.value;
      vm.tipoDocumento=id;
      vm.numeroDocumento="";
    },
    validateNumber(event){
      let val = event.key;
      let myRex = new RegExp('^[0-9]$');
      if(!myRex.test(val)) event.preventDefault();
    },
    validateNumberAndLetter(event){
      let val = event.key;
      let myRex = new RegExp('^[0-9a-zA-z]$');
      if(!myRex.test(val)) event.preventDefault();
    },
    validateLength(event){
      let vm = this;
      let val = event.target.value;
      let len = 20;
      if(vm.tipoDocumento=="DNI") len = 8;
      else if(vm.tipoDocumento=="RUC") len = 11;
      if(val.length>len){
        vm.numeroDocumento = event.target.value.substring(0,len);
      }
    },
    changeCiudad(event){
      let vm = this;
      let val = event.target.value;
      if(val == "Lima"){
        vm.distritos = [
          {id:1,nombre:"Surco"},
          {id:2,nombre:"La Molina"},
          {id:3,nombre:"San Luis"},
          {id:4,nombre:"Lurigancho"},
          {id:5,nombre:"Ate"},
          {id:6,nombre:"Santa Anita"},
          {id:7,nombre:"Lurin"},
          {id:8,nombre:"Surco"},
          {id:9,nombre:"San Borja"},
          {id:10,nombre:"Cercado de Lima"},
          {id:11,nombre:"San Juan de Lurigancho"},
          {id:12,nombre:"El Agustino"},
          {id:13,nombre:"Rimac"},
          {id:14,nombre:"San Juan de Miraflores"},
          {id:15,nombre:"Villa El Salvador"},
          {id:16,nombre:"Villa Maria del Triunfo"},
          {id:17,nombre:"Playa"},
          {id:18,nombre:"La Victoria"},
          {id:19,nombre:"Magdalena del Mar"},
          {id:20,nombre:"San Miguel"},
          {id:21,nombre:"Pueblo Libre"},
          {id:22,nombre:"Lince"},
          {id:23,nombre:"San Isidro"},
          {id:24,nombre:"Jesus Maria"},
          {id:25,nombre:"Barranco"},
          {id:26,nombre:"Miraflores"},
          {id:27,nombre:"Surquillo"},
          {id:28,nombre:"Callao"},
          {id:29,nombre:"Ventanilla"},
          {id:30,nombre:"Carabayllo"},
          {id:31,nombre:"Comas"},
          {id:32,nombre:"Los olivos"},
          {id:33,nombre:"Puente piedra"},
          {id:34,nombre:"San Martin de Porres"},
          {id:35,nombre:"Independencia"},
          {id:36,nombre:"Ancon"},
          {id:37,nombre:"Chorrillos"},
        ]
      }else if(val == "Trujillo"){
        vm.distritos = [
          {id:38,nombre:"Trujillo"},
          {id:39,nombre:"El Porvenir"},
          {id:40,nombre:"Florencia de Mora"},
          {id:41,nombre:"Huanchaco"},
          {id:42,nombre:"La Esperanza"},
          {id:43,nombre:"Laredo"},
          {id:44,nombre:"Moche"},
          {id:45,nombre:"Poroto"},
          {id:46,nombre:"Salaverry"},
          {id:47,nombre:"Simbal"},
          {id:48,nombre:"Victor Larco Herrera"},
        ]
      }else if(val == "Chiclayo"){
        vm.distritos = [
          {id:49,nombre:"Chiclayo"},
          {id:50,nombre:"Cayalti"},
          {id:51,nombre:"Changoyape"},
          {id:52,nombre:"Huanchaco"},
          {id:53,nombre:"Eten"},
          {id:54,nombre:"Puerto Eten"},
          {id:55,nombre:"Jose Leonardo Ortiz"},
          {id:56,nombre:"La Victoria"},
          {id:57,nombre:"Monsefú"},
          {id:58,nombre:"Nueva Arica"},
          {id:59,nombre:"Oyotún"},
          {id:60,nombre:"Pátapo"},
          {id:61,nombre:"Picsi"},
          {id:62,nombre:"Pimentel"},
          {id:63,nombre:"Pomalca"},
          {id:64,nombre:"Pucalá"},
          {id:65,nombre:"Reque"},
          {id:66,nombre:"Santa Rosa"},
          {id:67,nombre:"Tumán"},
          {id:68,nombre:"Saña"},
        ]
      }else if(val == "Arequipa"){
        vm.distritos = [
          {id:69,nombre:"Distrito de Alto Selva Alegre"},
          {id:70,nombre:"Arequipa"},
          {id:71,nombre:"Cayma"},
          {id:72,nombre:"Cerro Colorado"},
          {id:73,nombre:"Characato"},
          {id:74,nombre:"Chiguata"},
          {id:75,nombre:"Jacobo Hunter"},
          {id:76,nombre:"Jose Luis Bustamante y Rivero"},
          {id:77,nombre:"La Joya"},
          {id:78,nombre:"Mariano Melgar"},
          {id:79,nombre:"Miraflores"},
          {id:80,nombre:"Mollebaya"},
          {id:81,nombre:"Paucarpata"},
          {id:82,nombre:"Pocsi"},
          {id:83,nombre:"Polobaya"},
          {id:84,nombre:"Quequeña"},
          {id:85,nombre:"Sabandía"},
          {id:86,nombre:"Sachaca"},
          {id:87,nombre:"San Juan de Siguas"},
          {id:88,nombre:"San Juan de Tarucani"},
          {id:89,nombre:"Santa Isabel de Siguas"},
          {id:90,nombre:"Santa Rita de Siguas"},
          {id:91,nombre:"Socabaya"},
          {id:92,nombre:"Tiabaya"},
          {id:93,nombre:"Uchumayo"},
          {id:94,nombre:"Vitor"},
          {id:95,nombre:"Yanahuara"},
          {id:96,nombre:"Yarabamba"},
          {id:97,nombre:"Yura"},
        ]
      }else{
        vm.distritos = [];
      }
    },
    registrarFreshdesk(){
        let vm = this;

        vm.isTipoIncidencia = false;
        vm.isTipoDocumento = false;
        vm.isNumeroDocumento = false;
        vm.isSuscripcion = false;
        vm.isTipoLugar = false;
        vm.isLugar = false;
        vm.isTipoUbicacion = false;
        vm.isUbicacion = false;
        vm.isTipoEspacio = false;
        vm.isEspacio = false;
        vm.isUrbanizacion = false;
        vm.isCiudad = false;
        vm.isDistrito = false;
        vm.isReferencia = false;
        vm.isFormaEntrega = false;
        vm.isDiasIncidencia = false;
        vm.isObservacion = false;
        vm.isCorreo = false;

        if(vm.tipoIncidencia == ""){
            vm.isTipoIncidencia = true;
        }

        if(vm.tipoDocumento == ""){
            vm.isTipoDocumento = true;
        }

        if(vm.numeroDocumento == ""){
            vm.isNumeroDocumento = true;
        }

        if(vm.suscripcion == ""){
            vm.isSuscripcion = true;
        }

        if(vm.tipoLugar == ""){
            vm.isTipoLugar =true;
        }

        if(vm.lugar == ""){
            vm.isLugar =true;
        }

        if(vm.tipoUbicacion == ""){
            vm.isTipoUbicacion = true;
        }

        if(vm.ubicacion == ""){
            vm.isUbicacion = true;
        }

        if(vm.tipoEspacio == ""){
            vm.isTipoEspacio = true;
        }

        if(vm.espacio == ""){
            vm.isEspacio = true;
        }

        if(vm.urbanizacion == ""){
            vm.isUrbanizacion = true;
        }

        if(vm.ciudad == ""){
            vm.isCiudad = true;
        }

        if(vm.distrito == ""){
            vm.isDistrito = true;
        }

        if(vm.referencia == ""){
            vm.isReferencia = true;
        }

        if(vm.formaEntrega == ""){
            vm.isFormaEntrega = true;
        }

        if(vm.diasIncidencia == ""){
            vm.isDiasIncidencia = true;
        }

        if(vm.correo == ""){
            vm.isCorreo = true;
        }

        if(vm.observacion == ""){
            vm.isObservacion = true;
        }

        if(vm.isTipoIncidencia == false && vm.isTipoDocumento == false && vm.isNumeroDocumento == false && vm.isSuscripcion == false && vm.isTipoLugar == false && vm.isLugar == false && vm.isTipoUbicacion == false && vm.isUbicacion == false && vm.isTipoEspacio == false && vm.isEspacio == false && vm.isUrbanizacion ==  false && vm.isCiudad == false && vm.isDistrito == false && vm.isReferencia == false && vm.isFormaEntrega == false && vm.isDiasIncidencia == false && vm.isObservacion == false && vm.isCorreo == false){
         
            let diaIncidenciaInicio = new Date(vm.diasIncidencia[0]);
            diaIncidenciaInicio = `${diaIncidenciaInicio.getDate()}-${(diaIncidenciaInicio.getMonth() + 1) < 10 ? '0'+(diaIncidenciaInicio.getMonth() + 1):(diaIncidenciaInicio.getMonth() + 1)}-${diaIncidenciaInicio.getFullYear()}`;
            let diaIncidenciaFin = new Date(vm.diasIncidencia[1]);
            diaIncidenciaFin = `${diaIncidenciaFin.getDate()}-${(diaIncidenciaFin.getMonth() + 1) < 10 ? '0'+(diaIncidenciaFin.getMonth() + 1):(diaIncidenciaFin.getMonth() + 1)}-${diaIncidenciaFin.getFullYear()}`;

            if(!/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(vm.correo)){
              vm.$fire({
                title: "Seguridad",
                text: "La dirección de correo es incorrecta.",
                type: "info",
                timer: 4000
              }).then(r => {
                console.log("dismin",r);
              });
            }else{
              let data = {
                  tipoIncidencia:vm.tipoIncidencia,
                  tipoDocumento:vm.tipoDocumento,
                  numeroDocumento:vm.numeroDocumento,
                  suscripcion:vm.suscripcion,
                  tipoLugar:vm.tipoLugar,
                  lugar:vm.lugar,
                  tipoUbicacion:vm.tipoUbicacion,
                  ubicacion:vm.ubicacion,
                  tipoEspacio:vm.tipoEspacio,
                  espacio:vm.espacio,
                  urbanizacion:vm.urbanizacion,
                  ciudad:vm.ciudad,
                  distrito:vm.distrito,
                  referencia:vm.referencia,
                  formaEntrega:vm.formaEntrega,
                  diaIncidenciaInicio,
                  diaIncidenciaFin,
                  correo: vm.correo,
                  observacion:vm.observacion,
                  observacionIncompletaMaltratada:vm.observacionIncompletaMaltratada,
                  tipo:2
              };

              axios
              .post(`${process.env.VUE_APP_ROOT_API}/incidencias`,data)
              .then(function (res) {
                vm.isLoading = false;
                if(res.data.data.code==1){

                  vm.$fire({
                    title: "Felicitaciones",
                    text: res.data.data.message,
                    type: "success",
                    timer: 4000
                  }).then(r => {
                  });

                  vm.tipoIncidencia = "";
                  vm.tipoDocumento = "";
                  vm.numeroDocumento = "";
                  vm.suscripcion = "";
                  vm.tipoLugar = "";
                  vm.lugar = "";
                  vm.tipoUbicacion = "";
                  vm.ubicacion = "";
                  vm.tipoEspacio = "";
                  vm.espacio = "";
                  vm.urbanizacion = "";
                  vm.ciudad = "";
                  vm.distrito = "";
                  vm.referencia = "";
                  vm.formaEntrega = "";
                  vm.diasIncidencia = "";
                  vm.correo = "";
                  vm.observacion="";
                  vm.observacionIncompletaMaltratada = "";
                  vm.isCobservacionIncompletaMaltratada = false;

                }else{
                  vm.$fire({
                    title: "Seguridad",
                    html: res.data.data.message ,
                    type: "info",
                    timer: 10000
                  }).then(r => {
                    console.log("dismin",r);
                  });
                }
              }).catch(error => {
                vm.isLoading = false;
                  vm.$fire({
                    title: "Error",
                    text: "Ocurrio un error en el servidor",
                    type: "error",
                    timer: 4000
                  }).then(r => {
                  });
              });
            }
      }
    },
    redirectLanding(){
      let vm = this;
      vm.$router.push("/");
    }
  },
  mounted(){
    let vm = this;
    axios.interceptors.request.use(config => {
      vm.isLoading = true;
      return config;
    }, error => {
      return Promise.reject(error);
    });
    $("#pgb-container").hide();

    const date = new Date();
    const hour = date.getHours();
    
    if((hour >= 0 && hour < 7) ||  hour >= 19 ){
      vm.flag = true;
    }
  }
}
</script>