<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}

.mx-input {
  height: 3em !important;
}

body {
  background: red !important;
}

.mx-datepicker-range > input {
  height: 3em !important;
}

#header {
  position: relative !important;
}

.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 17px;
  font-weight: 500px;
  color: #000;
}

.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

.page-not-available {
  background-color: #4b92fc;
  height: 100vh;
  width: 100%;
  color: #fff;
  font-size: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-not-available > p {
  text-align: center;
}
.page-not-available > p:nth-child(1) {
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.page-not-available > p:nth-child(2) {
  color: rgb(34, 59, 102);
}
</style>

<template>
  <div>
    <header id="header">
      <a href="#hero-section">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
      </a>
      <nav>
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/">
            <img src="../../assets/img/arrow.png" style="width: 45px" />
          </router-link>
        </ul>
      </nav>
    </header>

    <div class="d-flex align-items-center container__login">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      >
      </loading>
      <CContainer fluid>
        <CRow class="justify-content-center">
          <CCol md="6">
            <CCard class="mx-md-4 mb-0">
              <CCardBody class="p-4">
                <CForm>
                  <h1 class="title__login" style="color: black !important">
                    Estado de Solicitud
                  </h1>

                  <hr style="border-style: solid; border-width: 1px" />
                  <!-- //TODO: Fecha de pago y Monto Pagado -->
                  <CRow>
                    <CCol md="12" sm="12">
                      <!--//TODO: Fecha de Pago -->
                      <label class="label__input">Número de Ticket</label>
                      <p
                        v-if="isTicketId"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El numero de ticket es incorrecto
                      </p>
                      <CInput
                        v-model="ticketId"
                        style="color: black !important"
                      >
                      </CInput>
                    </CCol>
                  </CRow>

                  <!-- //TODO: Enviar -->
                  <button
                    type="button"
                    class="btn-comercio btn__ingresar"
                    @click="viewTicket()"
                  >
                    Enviar
                  </button>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <br />
        <br />
        <CRow class="justify-content-center">
          <div class="card">
            <div class="card-header bg-light text-dark">Ticket <strong>#{{ ticket_id }}</strong></div>
            <div class="card-body">
                <p><strong>Estado de ticket:  </strong> {{ status }}</p>
                <p><strong>Fecha de creación:  </strong> {{ created_at }}</p>
                <p><strong>Tipo de Solicitud:  </strong> {{ category }}</p>
                <p><strong>Detalle de Solicitud:  </strong> {{ subcategory }}</p>
            </div>
          </div>
        </CRow>
      </CContainer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Table from "../base/Table.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ProgressBars from "../base/ProgressBars.vue";

export default {
  name: "PageIncidenciaCobroDoble",
  data() {
    return {
      /* FIXME: valores nesesarios */
      isLoading: false,
      fullPage: true,

      isTicketId: false,

      // INPUTS
      ticketId: "",

      // OUTPUTS
      status: "",
      ticket_id: "",
      created_at: "",
      category: "",
      subcategory: "",

      flag: false,
    };
  },

  components: {
    Loading,
    Table,
    DatePicker,
    ProgressBars,
  },
  methods: {
    /* TODO:validar tamaño del numero */
    viewTicket() {
      let vm = this;
      //vm.isLoading = true;
      vm.isTicketId = false;

      if (vm.ticketId == "") {
        vm.isTicketId = true;
      }

      let ticketid = vm.ticketId;

      let url = `${process.env.VUE_APP_ROOT_API}/support/view-ticket/${ticketid}`;
      axios
        .get(url)
        .then(function (res) {
          vm.isLoading = false;
          if (res.data.data.code == 1) {
            vm.status = res.data.data.status;
            vm.ticket_id = res.data.data.data.id;
            vm.created_at = res.data.data.data.created_at;
            vm.category = res.data.data.data.custom_fields.cf_support_category;
            vm.subcategory = res.data.data.data.custom_fields.cf_support_subcategory;
          } else {
            vm.$fire({
              title: "Seguridad",
              html: res.data.data.message,
              type: "info",
              timer: 6000,
            }).then((r) => {
              console.log("dismin", r);
            });
          }
        })
        .catch((error) => {
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: "Ocurrio un error en el servidor" + error.message,
            type: "error",
            timer: 6000,
          }).then((r) => {
            console.log(r);
          });
        });
    },

    redirectLanding() {
      let vm = this;
      vm.$router.push("/");
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();
  },
};
</script>