<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}

.mx-input {
  height: 3em !important;
}

body {
  background: red !important;
}

.mx-datepicker-range > input {
  height: 3em !important;
}

#header {
  position: relative !important;
}

.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 17px;
  font-weight: 500;
  color: #000;
}

.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

.page-not-available {
  background-color: #4b92fc;
  height: 100vh;
  width: 100%;
  color: #fff;
  font-size: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-not-available > p {
  text-align: center;
}
.page-not-available > p:nth-child(1) {
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.page-not-available > p:nth-child(2) {
  color: rgb(34, 59, 102);
}
</style>

<template>
  <div>
    <header id="header">
      <a href="#hero-section">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
      </a>
      <nav>
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/">
            <img src="../../assets/img/arrow.png" style="width: 45px" />
          </router-link>
        </ul>
      </nav>
    </header>
    <div class="d-flex align-items-center container__login">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      >
      </loading>
      <CContainer fluid>
        <CRow class="justify-content-center">
          <CCol md="6">
            <CCard class="mx-md-4 mb-0">
              <CCardBody class="p-4">
                <CForm>
                  <h1 class="title__login" style="color: black !important">
                    Atención al Cliente
                  </h1>

                  <hr style="border-style: solid; border-width: 1px" />


            <!-- Opcion -->
                  <div class="form-group">
                    <label class="label__input">Motivo</label>
                    <p
                      v-if="isMotivo"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo tipo de consulta está vacio
                    </p>
                    <select
                      v-model="motivo"
                      class="form-control"
                      style="color: black !important"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="Información de suscripción">
                        Información de suscripción
                      </option>
                      <option value="Club El Comercio">
                        Club El Comercio
                      </option>
                      <option value="PerúQuiosco">
                        PerúQuiosco
                      </option>
                      <option value="Problemas y reclamos">
                        Problemas y reclamos
                      </option>
                      <option value="Cambios y solicitudes">
                        Cambios y solicitudes
                      </option>
                    </select>
                  </div>

                <!-- SubOpcion -->
                  <div class="form-group">
                    <label class="label__input">Submotivo</label>
                    <p
                      v-if="isSubmotivo"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo tipo de consulta está vacio
                    </p>

                    <!-- opcion 1 -->
                    <select
                      v-if="motivo=='Información de suscripción' "
                      v-model="subMotivo"
                      class="form-control"
                      style="color: black !important"
                    >
                    <!-- Opcion 1 -->
                      <option value="">--Seleccionar--</option>
                      <option value="Estado de suscripción">
                        Estado de suscripción
                      </option>
                      <option value="Consulta de renovación">
                        Consulta de renovación
                      </option>
                      <option value="Comprobante de pago">
                        Comprobante de pago
                      </option>
                      <option value="Estado de anulación">
                        Estado de anulación
                      </option>
                      <option value="Estado de devolución">
                        Estado de devolución
                      </option>
                      <option value="Geniogramista">Geniogramista</option>
                    </select>

                    <!-- opcion 2 -->
                     <select
                      v-if="motivo=='Club El Comercio' "
                      v-model="subMotivo"
                      class="form-control"
                      style="color: black !important"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="Cómo ser parte del Club">
                        Cómo ser parte del Club
                      </option>
                      <option value="Cómo usar mis beneficios">
                        Cómo usar mis beneficios
                      </option>
                      <option value="Promociones">
                        Promociones
                      </option>
                      <option value="Cambiar un beneficiario">
                        Cambiar un beneficiario
                      </option>
                      <option value="Actualizar mis datos">
                        Actualizar mis datos
                      </option>
                      <option value="Reclamo Club suscriptores">Reclamo Club suscriptores</option>
                    </select>

                    <!-- opcion 3 -->
                     <select
                      v-if="motivo=='PerúQuiosco' "
                      v-model="subMotivo"
                      class="form-control"
                      style="color: black !important"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="Consulta de PQ">
                        Consulta de PQ
                      </option>
                      <option value="Activación de cuenta">
                        Activación de cuenta
                      </option>
                      <option value="Cambio de contraseña">
                        Cambio de contraseña
                      </option>
                      <option value="Problemas de acceso">
                        Problemas de acceso
                      </option>
                    </select>
                    
                    <!-- opcion 4-->
                     <select
                      v-if="motivo=='Cambios y solicitudes' "
                      v-model="subMotivo"
                      class="form-control"
                      style="color: black !important"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="Datos de titularidad">
                        Datos de titularidad
                      </option>
                      <option value="Datos de contacto">
                        Datos de contacto
                      </option>
                      <option value="Dirección y forma de entrega">
                        Dirección y forma de entrega
                      </option>
                      <option value="Datos de facturación">
                        Datos de facturación
                      </option>
                      <option value="Datos en PerúQuiosco">
                        Datos en PerúQuiosco
                      </option>
                      <option value="Periodo de renovación">
                        Periodo de renovación
                      </option>
                        <option value="Paquete contratado">
                        Paquete contratado
                      </option>
                    <option value="Cambiar de diario">
                        Cambiar de diario
                      </option>
                        <option value="Cambiar a PerúQuiosco">
                        Cambiar a PerúQuiosco
                      </option>
                    </select>

                    <!-- opcion 5 -->
                    <select
                      v-if="motivo=='Problemas y reclamos' "
                      v-model="subMotivo"
                      class="form-control"
                      style="color: black !important"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="Problemas de reparto">
                        Problemas de reparto
                      </option>
                      <option value="Inconvenientes con la versión digital">
                        Inconvenientes con la versión digital
                      </option>
                      <option value="Problema con PQ">
                        Problema con PQ
                      </option>
                      <option value="Reclamo Club El Comercio">
                        Reclamo Club El Comercio
                      </option>
                    </select>
                  </div>










                  <div class="form-group">
                    <label class="label__input">Tipo de documento</label>
                    <p
                      v-if="isTipoDocumento"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo tipo de documento está vacio
                    </p>
                    <select
                      v-model="tipoDocumento"
                      class="form-control"
                      style="color: black !important"
                      @change="changeTipoDocumento($event)"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="DNI">DNI</option>
                      <option value="RUC">RUC</option>
                      <option value="Carnet de extranjeria">
                        Carné de extranjería
                      </option>
                      <option value="Pasaporte">Pasaporte</option>
                      <option value="CDI">CDI</option>
                    </select>
                  </div>
                  <label class="label__input">Número de documento</label>
                  <p
                    v-if="isNumeroDocumento"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo número de documento está vacio
                  </p>
                  <CInput
                    v-model="numeroDocumento"
                    @keypress="validateNumber($event)"
                    @keyup="validateLength($event)"
                    style="color: black !important"
                  >
                  </CInput>

                  <div class="form-group">
                    <label class="label__input">Suscripción contratada</label>
                    <p
                      v-if="isSuscripcion"
                      style="
                        color: red;
                        font-size: 0.8em;
                        margin: 0 0 5px 0;
                        padding: 0;
                      "
                    >
                      (*)El campo suscripción está vacio
                    </p>
                    <select
                      v-model="suscripcion"
                      class="form-control"
                      style="color: black !important"
                    >
                      <option value="">--Seleccionar--</option>
                      <option value="El Comercio Impreso">
                        El Comercio Impreso
                      </option>
                      <option value="El Comercio Impreso + Digital">
                        El Comercio Impreso + Digital
                      </option>
                      <option value="Gestión Impreso">Gestión Impreso</option>
                      <option value="Gestión Impreso + Digital">
                        Gestión Impreso + Digital
                      </option>
                    </select>
                  </div>
                  <label class="label__input"
                    >Número telefónico
                    <!-- para contactarte --></label
                  >
                  <p
                    v-if="isNumeroTelefonico"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo número telefónico está vacio
                  </p>
                  <CInput
                    v-model="numeroTelefonico"
                    @keypress="validateNumber($event)"
                    maxlength="9"
                    style="color: black !important"
                  >
                  </CInput>

                  <label class="label__input">Observación</label>
                  <p
                    v-if="isObservacion"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo observacion está vacio
                  </p>
                  <CTextarea
                    v-model="observacion"
                    style="color: black !important"
                  ></CTextarea>
                  <label class="label__input">Correo</label>
                  <p
                    v-if="isCorreo"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo correo está vacio
                  </p>
                  <CInput v-model="correo" style="color: black !important">
                  </CInput>
                  <button
                    type="button"
                    class="btn-comercio btn__ingresar"
                    @click="registrarFreshdesk()"
                  >
                    Enviar
                  </button>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Table from "../base/Table.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "PageFueraServicio",
  data() {
    return {
      isLoading: false,
      fullPage: true,

      isMotivo: false,
      motivo: "",
      isSubmotivo: false,
      subMotivo: "",
      isTipoDocumento: false,
      tipoDocumento: "",
      isNumeroDocumento: false,
      numeroDocumento: "",
      isSuscripcion: false,
      suscripcion: "",
      isNumeroTelefonico: false,
      numeroTelefonico: "",
      isObservacion: false,
      observacion: "",
      isCorreo: false,
      correo: "",
      flag: false,
    };
  },
  components: {
    Loading,
    Table,
    DatePicker,
  },
  methods: {
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    validateNumberAndLetter(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9a-zA-z]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    validateLength(event) {
      let vm = this;
      let val = event.target.value;
      let len = 20;
      if (vm.tipoDocumento == "DNI") len = 8;
      else if (vm.tipoDocumento == "RUC") len = 11;
      if (val.length > len) {
        vm.numeroDocumento = event.target.value.substring(0, len);
      }
    },
    changeTipoDocumento(event) {
      let vm = this;
      let id = event.target.value;
      vm.tipoDocumento = id;
      vm.numeroDocumento = "";
    },

    registrarFreshdesk() {
      let vm = this;

      vm.isMotivo = false;
      vm.isSubmotivo = false;
      vm.isTipoDocumento = false;
      vm.isNumeroDocumento = false;
      vm.isSuscripcion = false;
      vm.isNumeroTelefonico = false;
      vm.isObservacion = false;
      vm.isCorreo = false;

      if (vm.motivo == "") {
        vm.isMotivo = true;
      }
        if (vm.subMotivo == "") {
        vm.isSubmotivo = true;
      }

      if (vm.tipoDocumento == "") {
        vm.isTipoDocumento = true;
      }

      if (vm.numeroDocumento == "") {
        vm.isNumeroDocumento = true;
      }

      if (vm.suscripcion == "") {
        vm.isSuscripcion = true;
      }

      if (vm.numeroTelefonico == "") {
        vm.isNumeroTelefonico = true;
      }

      if (vm.observacion == "") {
        vm.isObservacion = true;
      }

      if (vm.correo == "") {
        vm.isCorreo = true;
      }

      if (
        vm.isMotivo == false &&
        vm.isSubmotivo == false &&
        vm.isTipoDocumento == false &&
        vm.isNumeroDocumento == false &&
        vm.isSuscripcion == false &&
        vm.isNumeroTelefonico == false &&
        vm.isObservacion == false &&
        vm.isCorreo == false
      ) {
        if (
          vm.numeroTelefonico.length != 7 &&
          vm.numeroTelefonico.length != 9
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "El campo número telefónico debe contener 7 u 9 números.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else if (
          vm.numeroTelefonico.length == 9 &&
          vm.numeroTelefonico.charAt(0) != "9"
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "El campo número telefónico debe comenzar con el dígito 9.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else if (
          !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            vm.correo
          )
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "La dirección de correo es incorrecta.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else {
          let data = {
            motivo: vm.motivo,
            submotivo: vm.subMotivo,
            tipoDocumento: vm.tipoDocumento,
            numeroDocumento: vm.numeroDocumento,
            suscripcion: vm.suscripcion,
            numeroTelefonico: vm.numeroTelefonico,
            observacion: vm.observacion,
            correo: vm.correo,
            tipo: 404,
          };
          console.log("Data =>", data);
          axios
            .post(`${process.env.VUE_APP_ROOT_API}/fuera-servicio`, data)
            .then(function (res) {
              vm.isLoading = false;
              if (res.data.data.code == 1) {
                vm.$fire({
                  title: "Felicitaciones",
                  text: res.data.data.message,
                  type: "success",
                  timer: 4000,
                }).then((r) => {});
                vm.motivo = "";
                vm.subMotivo = "";
                vm.tipoDocumento = "";
                vm.numeroDocumento = "";
                vm.suscripcion = "";
                vm.numeroTelefonico = "";
                vm.observacion = "";
                vm.correo = "";
              } else {
                vm.$fire({
                  title: "Seguridad",
                  html: res.data.data.message,
                  type: "info",
                  timer: 10000,
                }).then((r) => {
                  console.log("dismin", r);
                });
              }
            })
            .catch((error) => {
              vm.isLoading = false;
              vm.$fire({
                title: "Error",
                text: "Ocurrio un error en el servidor",
                type: "error",
                timer: 4000,
              }).then((r) => {});
            });
        }
      }
    },
    redirectLanding() {
      let vm = this;
      vm.$router.push("/");
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();

    const date = new Date();
    const hour = date.getHours();

    if ((hour >= 0 && hour < 7) || hour >= 19) {
      vm.flag = true;
    }
  },
};
</script>