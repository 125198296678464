<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}

.mx-input {
  height: 3em !important;
}

body {
  background: red !important;
}

.mx-datepicker-range > input {
  height: 3em !important;
}

#header {
  position: relative !important;
}

.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 17px;
  font-weight: 500px;
  color: #000;
}

.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

.page-not-available {
  background-color: #4b92fc;
  height: 100vh;
  width: 100%;
  color: #fff;
  font-size: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-not-available > p {
  text-align: center;
}
.page-not-available > p:nth-child(1) {
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.page-not-available > p:nth-child(2) {
  color: rgb(34, 59, 102);
}
</style>

<template>
  <div>
    <header id="header">
      <a href="#hero-section">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
      </a>
      <nav>
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/">
            <img src="../../assets/img/arrow.png" style="width: 45px" />
          </router-link>
        </ul>
      </nav>
    </header>

    <div class="d-flex align-items-center container__login">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      >
      </loading>
      <CContainer fluid>
        <CRow class="justify-content-center">
          <CCol md="6">
            <CCard class="mx-md-4 mb-0">
              <CCardBody class="p-4">
                <CForm>
                  <h1 class="title__login" style="color: black !important">
                    Incidencia Cobro Doble
                  </h1>

                  <hr style="border-style: solid; border-width: 1px" />
                  <!-- //TODO: Fecha de pago y Monto Pagado -->

                  <CRow>
                    <CCol md="6" sm="12">
                      <!--//TODO: Fecha de Pago -->
                      <label class="label__input">Fecha de pago </label>
                      <p
                        v-if="isFechaPago"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)Debe Seleccionar Una Fecha
                      </p>
                      <CInput v-model="fechaPago" type="date"> </CInput>
                    </CCol>
                    <CCol md="6" sm="12">
                      <!--//TODO: Monto Pagago-->
                      <label class="label__input">Monto pagado </label>
                      <p
                        v-if="isMontoPagado"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)Falta Monto Pagado
                      </p>
                      <CInput
                        @keypress="validateNumberAndPoint($event)"
                        v-model="montoPagado"
                        placeholder="50.90"
                      >
                      </CInput>
                    </CCol>
                  </CRow>
                  <!--//TODO: numero Tarjeta-->
                  <CRow>
                    <CCol sm="6" md="6">
                      <label class="label__input">Número de Tarjeta</label>
                      <p
                        v-if="isNumeroTarjetaInicial"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*) El campo esta vacio
                      </p>
                      <CInput
                        v-model="numeroTarjetaInicial"
                        placeholder="Primeros 6 digitos de tu tarjeta"
                        @keypress="validateNumber($event)"
                        maxlength="6"
                      >
                      </CInput>
                    </CCol>
                    <CCol sm="6" md="6">
                      <p
                        v-if="isNumeroTarjetaFinal"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*) El campo esta vacio
                      </p>
                      <CInput
                        style="margin-top: 32px; !important"
                        v-model="numeroTarjetaFinal"
                        placeholder="ultimos 4 digitos de tu tarjeta"
                        @keypress="validateNumber($event)"
                        maxlength="4"
                      >
                      </CInput>
                    </CCol>
                  </CRow>
                  <!--//TODO: Constancia de Cobro-->
                  <CRow>
                    <CCol sm="6" md="12" class="mb-2">
                      <label class="label__input" for="archivo"
                        >Constancia de cobro (imagen, pdf)</label
                      >
                      <input
                        @change="updateFile"
                        name="archivo"
                        type="file"
                        class="form-control-file"
                      />
                      <p
                        v-if="isConstanciaCobro"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*) El Campo Esta Vacio
                      </p>
                    </CCol>
                  </CRow>
                  <!-- //TODO: correo -->
                  <!-- //TODO: Enviar -->
                  <button
                    type="button"
                    class="btn-comercio btn__ingresar"
                    @click="registerDobleCobro()"
                  >
                    Enviar
                  </button>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Table from "../base/Table.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ProgressBars from "../base/ProgressBars.vue";

export default {
  name: "PageIncidenciaCobroDoble",
  data() {
    return {
      positionid: null,
      nrodoc: null,
      typedoc: null,
      token: null,
      /* FIXME: valores nesesarios */
      isLoading: false,
      fullPage: true,

      isFechaPago: false,
      isMontoPagado: false,
      isNumeroTarjetaInicial: false,
      isNumeroTarjetaFinal: false,
      isConstanciaCobro: false,

      fechaPago: "",
      montoPagado: "",
      numeroTarjetaInicial: "",
      numeroTarjetaFinal: "",
      constanciaCobro: "",
      fileBase64: "",

      flag: false,
    };
  },

  components: {
    Loading,
    Table,
    DatePicker,
    ProgressBars,
  },
  methods: {
    /* TODO: Codificando el archivo en Base64*/
    async updateFile(e) {
      let vm = this;
      const file = e.target.files[0];
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        vm.fileBase64 = fileReader.result;
        //console.log(file);
      };
    },
    /* TODO: Validaciones */
    /* TODO:validar tipo documento */
    /* TODO:validar numero */
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    /* TODO:validar tamaño del numero */
    validateNumberAndPoint(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9.]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    registerDobleCobro() {
      let vm = this;

      vm.isFechaPago = false;
      vm.isMontoPagado = false;
      vm.isNumeroTarjetaInicial = false;
      vm.isNumeroTarjetaFinal = false;

      if (vm.fechaPago == "") {
        vm.isFechaPago = true;
      }

      if (vm.montoPagado == "") {
        vm.isMontoPagado = true;
      }

      if (vm.numeroTarjetaInicial == "") {
        vm.isNumeroTarjetaInicial = true;
      }
      if (vm.numeroTarjetaFinal == "") {
        vm.isNumeroTarjetaFinal = true;
      }

      if (
        vm.isFechaPago == false &&
        vm.isMontoPagado == false &&
        vm.isNumeroTarjetaInicial == false &&
        vm.isNumeroTarjetaFinal == false &&
        vm.isConstanciaCobro == false
      ) {
        this.nrodoc = this.$route.params.nrodoc;
        this.typedoc = this.$route.params.typedoc;
        this.positionid = this.$route.params.positionid;
        this.token = this.$route.params.token;

        let data = {
          constanciaCobro: this.fileBase64,
          document_type: this.typedoc,
          document_number: this.nrodoc,
          positionId: this.positionid,
          fechaPago: this.fechaPago,
          montoPagado: this.montoPagado,
          numeroTarjetaInicial: this.numeroTarjetaInicial,
          numeroTarjetaFinal: this.numeroTarjetaFinal,
          token: this.token,
        };

        //console.log("Data =>", data.constanciaCobro);

        axios
          .post(
            `${process.env.VUE_APP_ROOT_API_COBRO_DOBLE}/complaints-doblecobro`,
            data
          )
          .then(function (res) {
            vm.isLoading = false;
            if (res.data.data.code == 1) {
              vm.$fire({
                title: "Constancia de reclamo enviada",
                html: `${res.data.data.message} <br/><br/> Gracias por escribirnos.`,
                type: "success",
                timer: 6000,
              }).then((r) => {});

              vm.fechaPago = "";
              vm.montoPagado = "";
              vm.numeroTarjetaInicial = "";
              vm.numeroTarjetaFinal = "";
              vm.constanciaCobro = "";
            } else {
              vm.$fire({
                title: "Seguridad",
                html: `No se registro su solicitud, Intentelo más tarde o llame al 311-5000`,
                type: "info",
                timer: 6000,
              }).then((r) => {
                console.log("dismin", r);
              });
            }
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "Ocurrio un error en el servidor" + error.message,
              type: "error",
              timer: 6000,
            }).then((r) => {
              console.log(r);
            });
          });
      }
    },

    redirectLanding() {
      let vm = this;
      vm.$router.push("/");
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();
  },
};
</script>